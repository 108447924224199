<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="this.pageTitle"
      :subtitle="'Siga los pasos para completar el proceso'"
      :hide-buttons="false"
    >
      <tab-content v-if="$route.params.category === 'calculators'" title="Datos Generales" class="mb-5" :before-change="validateGeneralData">
        <form data-vv-scope="general-data">
          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="name" class="w-full select-large">Nombre</label>
              <vs-input
                  id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full"
                  autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.name") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="title" class="w-full select-large">Título</label>
              <vs-input
                  id="title" name="title" v-model="entryModel.title" v-validate="'required'" class="w-full"
                  autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.title") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="description" class="w-full select-large">Descripción</label>
              <editor
                  id="description" name="description"
                  :content.sync="entryModel.description.html" :resourceType="resource"
              />
              <br/>
            </div>
          </div>
          <div class="vx-row">
            <div v-if="showPriority" class="vx-col md:w-1/3 w-full">
              <label for="priority" class="w-full select-large">Prioridad</label>
              <vs-input
                  type="number" id="priority" name="priority" min="1" max="10"
                  v-model="entryModel.priority" v-validate="'required'"
                  class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.priority") }}</span>
              <br/>
            </div>
            <div :class="{'vx-col md:w-1/3 w-full': showPriority && showFeatured, 'vx-col w-full': !showPriority && !showFeatured}">
              <label for="approved" class="w-full select-large">Aprobado</label>
              <vs-select
                  id="approved" name="approved"
                  v-model="entryModel.approved" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.approved") }}</span>
              <br/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Tipo de calculadora</label>
              <vs-select
                id="featured" name="featured"
                v-model="entryModel.settings.calculator_id"
                object
                class="w-full select-large"
              >
                <vs-select-item
                  key="calculator-prestamo-vehicular"
                  value="calculator-prestamo-vehicular"
                  text="Préstamo vehicular" class="w-full"
                />
                <vs-select-item
                  key="calculator-prestamo-personal"
                  value="calculator-prestamo-personal"
                  text="Préstamo personal"
                  class="w-full"
                />
                <vs-select-item
                  key="calculator-prestamo-dabuenavida"
                  value="calculator-prestamo-dabuenavida"
                  text="Préstamo DabuenaVida"
                  class="w-full"
                />
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.show_form") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Mostrar calculadora</label>
              <vs-select
                  id="featured" name="featured"
                  v-model="entryModel.form_placement" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="'before_content'" :value="'before_content'" :text="'Antes del contenido'" class="w-full"/>
                <vs-select-item :key="'after_content'" :value="'after_content'" :text="'Después del contenido'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.form_placement") }}</span>
              <br/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="publish_at" class="w-full select-large">Fecha publicación</label>
              <flat-pickr
                  :config="configdateTimePicker"
                  id="publish_at" name="publish_at" placeholder="Fecha y hora de publicación"
                  v-model="entryModel.publish_at"
                  class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <label for="expire_at" class="w-full select-large">Fecha expiración</label>
              <flat-pickr
                  :config="configdateTimePicker"
                  id="expire_at" name="expire_at" placeholder="Fecha y hora de expiración"
                  v-model="entryModel.expire_at"
                  class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
          </div>
        </form>
      </tab-content>

      <tab-content v-else title="Datos Generales" class="mb-5" :before-change="validateGeneralData">
        <form data-vv-scope="general-data">
          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="name" class="w-full select-large">Nombre</label>
              <vs-input
                id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.name") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="title" class="w-full select-large">Título</label>
              <vs-input
                id="title" name="title" v-model="entryModel.title" v-validate="'required'" class="w-full"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.title") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="description" class="w-full select-large">Descripción</label>
              <editor
                id="description" name="description"
                :content.sync="entryModel.description.html" :resourceType="resource"
              />
              <br/>
            </div>
          </div>
          <div class="vx-row">
            <div v-if="showFeatured" class="vx-col md:w-1/3 w-full">
              <label for="featured" class="w-full select-large">Página principal</label>
              <vs-select
                id="featured" name="featured"
                v-model="entryModel.featured" v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.featured") }}</span>
              <br/>
            </div>
            <div v-if="showPriority" class="vx-col md:w-1/3 w-full">
              <label for="priority" class="w-full select-large">Prioridad</label>
              <vs-input
                type="number" id="priority" name="priority" min="1" max="10"
                v-model="entryModel.priority" v-validate="'required'"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.priority") }}</span>
              <br/>
            </div>
            <div :class="{'vx-col md:w-1/3 w-full': showPriority && showFeatured, 'vx-col w-full': !showPriority && !showFeatured}">
              <label for="approved" class="w-full select-large">Aprobado</label>
              <vs-select
                id="approved" name="approved"
                v-model="entryModel.approved" v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.approved") }}</span>
              <br/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Mostrar formulario</label>
              <vs-select
                  id="featured" name="featured"
                  v-model="entryModel.show_form" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.show_form") }}</span>
              <br/>
            </div>
            <div v-if="entryModel.show_form" class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Mostrar formulario</label>
              <vs-select
                  id="featured" name="featured"
                  v-model="entryModel.form_placement" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="'on_banner_bar'" :value="'on_banner_bar'" :text="'En banner'" class="w-full"/>
                <vs-select-item :key="'before_content'" :value="'before_content'" :text="'Antes del contenido'" class="w-full"/>
                <vs-select-item :key="'after_content'" :value="'after_content'" :text="'Después del contenido'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.form_placement") }}</span>
              <br/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="publish_at" class="w-full select-large">Fecha publicación</label>
              <flat-pickr
                :config="configdateTimePicker"
                id="publish_at" name="publish_at" placeholder="Fecha y hora de publicación"
                v-model="entryModel.publish_at"
                class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <label for="expire_at" class="w-full select-large">Fecha expiración</label>
              <flat-pickr
                :config="configdateTimePicker"
                id="expire_at" name="expire_at" placeholder="Fecha y hora de expiración"
                v-model="entryModel.expire_at"
                class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 2 content -->
      <tab-content title="Contenido" class="mb-5">
        <div class="vx-row">
          <div class="vx-col w-full">
            <builder :html.sync="entryModel.content" :resource="resource"/>
          </div>
        </div>
      </tab-content>

      <!-- tab 3 content -->
      <tab-content title="Medios" class="mb-5">
        <vs-divider class="vx-col w-full mt-5" position="right" color="light">Open graph</vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-1'"
              :maxNumberOfFiles="1"
              :downloadedFiles="entryModel.media.open_graph"
              @files-removed="removedFiles('open_graph')"
              @files-updated="openGraphUpload"
            />
          </div>
        </div>

        <vs-divider class="vx-col w-full mt-5" position="right" color="light">Banner</vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-2'"
              :maxNumberOfFiles="1"
              :downloadedFiles="entryModel.media.banner"
              @files-removed="removedFiles('banner')"
              @files-updated="bannerUpload"
            />
          </div>
        </div>

        <vs-divider class="vx-col w-full mt-5" position="right" color="light">Thumbnail</vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-3'"
              :maxNumberOfFiles="1"
              @files-removed="removedFiles('thumbnail')"
              @files-updated="thumbnailUpload"
              :downloadedFiles="entryModel.media.thumbnail"
            />
          </div>
        </div>
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <!-- Prev button -->
          <vs-button
            v-if="props.activeTabIndex > 0"
            @click.native="props.prevTab()"
            class="wizard-footer-left finish-button"
          >
            Anterior
          </vs-button>
        </div>

        <div class="wizard-footer-right">
          <!-- General data next button -->
          <vs-button
            v-if="props.activeTabIndex === 0"
            class="wizard-footer-right finish-button"
            @click.native="props.nextTab()"
            :disabled="!requiredGeneralDataFieldsFilled"
          >
            Siguiente
          </vs-button>

          <!-- Any other tab -->
          <vs-button
            v-if="props.activeTabIndex !== 0 && !props.isLastStep"
            class="wizard-footer-right finish-button"
            @click.native="props.nextTab()"
          >
            Siguiente
          </vs-button>

          <!-- Last tab -->
          <vs-button
            v-if="props.isLastStep"
            class="wizard-footer-right finish-button"
            @click="createOrUpdateEntry"
          >
            Guardar
          </vs-button>

          <!-- Shown in every tab -->
          <vs-button
            color="danger"
            type="border"
            class="wizard-footer-right finish-button mr-3"
            @click="goToEntriesList"
          >
            Cancelar
          </vs-button>
        </div>
      </template>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Configurations from "./../../utils/configurations";
  import Upload from "./../../utils/upload";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import Editor from "./../../components/Editor.vue";
  import Builder from "./../../components/Builder.vue";
  import Uploader from "./../../components/Uploader.vue";
  import {Validator} from "vee-validate";
  import {EventBus} from "../../utils/event-bus";

  let notifier = new Notifier();
  let configurations = new Configurations();
  const ENTRY_CLIENT = new EntriesClient("static-pages");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("static-pages");
  const UPLOAD = new Upload("resource");

  let requiredGeneralDataFields = ["institution", "name", "title", "description", "featured", "priority", "approved"];
  let requiredGeneralDataFieldsCustomMessages = requiredGeneralDataFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: requiredGeneralDataFieldsCustomMessages});

  export default {
    data() {
      return {
        configdateTimePicker: configurations.configDatePicker(),
        showPriority: !["landings", "promotional_items"].includes(this.$route.params.category),
        showFeatured: !["landings", "promotional_items"].includes(this.$route.params.category),
        relatedEntries: [],
        resource: "static-pages",
        pageTitle: "",
        institutionsMapper: {
          bank: { id: "bank", code: "banco", name: "Banco", slug: "banco" },
          insurances: { id: "insurances", code: "seguros", name: "Seguros", slug: "seguros" },
          companies: { id: "companies", code: "empresas", name: "Empresas", slug: "empresas" },
        },
        entryModel: {
          name: null,
          title: null,
          institution: null,
          category: null,
          description: {
            html: null
          },
          media: {
            open_graph: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
            banner: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
            thumbnail: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
          },
          content: {
            assets: null,
            html: null,
            css: null
          },
          featured: false,
          approved: false,
          priority: 10,
          publish_at: null,
          expire_at: null,
          show_form: true,
          form_placement: 'on_banner_bar',
          settings: {},
        }
      };
    },

    mounted() {
      this.entryModel.institution = this.institutionsMapper[this.$route.params.institution_id];
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id)
          .then(() => {
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading();
        this.setCategory()
          .then(() => {
            this.$vs.loading.close();
          });
      }
    },

    computed: {
      requiredGeneralDataFieldsFilled() {
        return !this.errors.any("general-data")
          && this.entryModel.institution !== null
          && this.entryModel.name !== null && this.entryModel.name.trim() !== ""
          && this.entryModel.title !== null && this.entryModel.title.trim() !== ""
          && this.entryModel.priority !== null
          && this.entryModel.description.html !== null && this.entryModel.description.html.trim() !== "";
      },
      formattedContent() {
        return {
          "gjs-html": this.entryModel.content["html"],
          "gjs-assets": this.entryModel.content["assets"],
          "gjs-css": this.entryModel.content["css"],
        }
      }
    },

    methods: {
      async setCategory() {
        await ATTRIBUTE_OPTIONS_CLIENT.category({pathParameters: {categoryId: this.$route.params.category}})
          .then(response => {
            this.pageTitle = response.data.name;
            this.entryModel.category = response.data;
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.setCategory(response.data.category.id, false)
              .then(() => {
                Object.assign(this.entryModel, response.data);
                this.getRelatedEntries();
              });
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.$vs.loading();
        EventBus.$emit('saveBuilder');

        let sendData = {};

        this.cleanModel();
        Object.assign(sendData, this.entryModel);
        sendData.content = this.formattedContent;

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: sendData})
            .then(response => {
              if (this.relatedEntries.length > 0) {
                this.updateRelatedEntry();
              } else {
                this.$vs.loading.close();
                notifier.notification("updated");
                this.goToEntriesList();
              }
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        } else {
          await ENTRY_CLIENT.create({data: sendData})
            .then(response => {
              if (
                [
                  "privacy_and_security_policy",
                  "corporate_social_responsibility",
                  "terms_and_conditions",
                ].includes(this.$route.params.category)
              ) {
                this.createRelatedEntry();
              } else {
                this.$vs.loading.close();
                notifier.notification("created");
                this.goToEntriesList();
              }
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      async getRelatedEntries() {
        let institutionsIds = [];

        if (this.$route.params.institution_id === "bank") {
          institutionsIds = ["insurances", "companies"];
        } else if (this.$route.params.institution_id === "insurances") {
          institutionsIds = ["bank", "companies"];
        } else if (this.$route.params.institution_id === "companies") {
          institutionsIds = ["bank", "insurances"];
        }

        await ENTRY_CLIENT.all({
          queryStrings: {
            category_id: this.$route.params.category,
            institution_id: institutionsIds,
            name_icontains: this.entryModel.name
          }
        })
          .then(response => {
            if (response.data.length > 0) {
              this.relatedEntries = response.data.map(({ id, institution }) => ({ id, institution }));
            }
          })
          .catch(error => {
          });
      },

      async createRelatedEntry() {
        let relatedEntryData = {};

        Object.assign(relatedEntryData, this.entryModel);

        if (this.$route.params.institution_id === "bank") {
          relatedEntryData.institution = this.institutionsMapper["insurances"];
        } else if (this.$route.params.institution_id === "insurances") {
          relatedEntryData.institution = this.institutionsMapper["bank"];
        }

        relatedEntryData.content = this.formattedContent;

        await ENTRY_CLIENT.create({
          data: relatedEntryData
        })
          .then(response => {
            this.$vs.loading.close();
            notifier.notification("created");
            this.goToEntriesList();
          })
          .catch(error => {
            notifier.alertMessage("error");
          });
      },

      async updateRelatedEntry() {
        try {
          let relatedEntryData = {};

          Object.assign(relatedEntryData, this.entryModel);

          const updatePromises = this.relatedEntries.map(relatedEntry => {
            const data = { ...relatedEntryData, institution: relatedEntry.institution, content: this.formattedContent };
            return ENTRY_CLIENT.update({
              pathParameters: { entryId: relatedEntry.id },
              data
            });
          });

          await Promise.all(updatePromises);

          await notifier.notification("updated");
          this.goToEntriesList();
        } catch (error) {
          await notifier.alertMessage("error");
        }
      },

      async openGraphUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.open_graph.url = response;
              this.entryModel.media.open_graph.meta.title = files[0].meta.name;
              this.entryModel.media.open_graph.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
        }
      },

      async bannerUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.banner.url = response;
              this.entryModel.media.banner.meta.title = files[0].meta.name;
              this.entryModel.media.banner.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
        }
      },

      async thumbnailUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.thumbnail.url = response;
              this.entryModel.media.thumbnail.meta.title = files[0].meta.name;
              this.entryModel.media.thumbnail.meta.description = files[0].meta.caption;
              this.$vs.loading.close();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.notification("error");
            });
        }
      },

      async removedFiles(files) {
        let mediaType = this.entryModel.media;
        let body = mediaType[files].url;

        body = JSON.stringify({url: body});

        await UPLOAD.removeFiles({data: body}).then(response => {
          mediaType[files] = {
            url: null,
            meta: {
              title: null,
              description: null
            }
          }
        }).catch(error => {
          notifier.notification("error");
        });
      },

      goToEntriesList() {
        this.$router.push({
          name: "single-category-static-pages-list",
          params: {category: this.$route.params.category, institution_id: this.$route.params.institution_id}
        });
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      validateGeneralData() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("general-data").then(result => {
            if (result) {
              resolve(true);
            } else {
              reject("Please fix invalid fields");
            }
          })
        });
      }
    },
    components: {
      Editor,
      FormWizard,
      TabContent,
      flatPickr,
      Builder,
      Uploader,
      "v-select": vSelect
    }
  };
</script>
